import React from "react"
import { Link, graphql, StaticQuery } from "gatsby"
import Layout from "src/components/layout"
import SubpageBanner from "src/components/SubpageBanner"
import SEO from "src/components/seo"
import SimpleText from "src/components/simple-text"
import Breadcrumbs from "src/components/Breadcrumbs"
import Sidebar from "src/components/Sidebar"

const breadcrumbs = [
  {url: "womens-health-impact", handle: "Women's Health Impact"},
  {
    url: "womens-health-impact/cervical-cancer-screening",
    handle: "Cervical Cancer Screening",
  },
]

const CerivcalCancerScreening = () => (
  <StaticQuery
    query={graphql`
      query {
        one: file(relativePath: { eq: "history.jpg" }) {
          childImageSharp {
            fixed(quality: 100) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        background: file(relativePath: { eq: "discarded-cells-banner.jpg" }) {
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={data => {
      const one = data.one.childImageSharp.fixed
      const background = data.background.childImageSharp.fluid

      return (
        <Layout>
          <SEO title="Cervical Cancer Screening" />
          <SubpageBanner image={background} heading="Cervical Cancer Screening" />
          <div className="wrapper wrapper--narrow p-bottom p-sides">
            <Breadcrumbs items={breadcrumbs} />
            <div className="split-contain wrapper">
              <div className="split-contain__sidebar">
                <Sidebar image={one} />
              </div>
              <div className="split-contain__contain">
                <SimpleText copy="All women are at risk for cervical cancer, though it occurs most often in women over age 30. According to the CDC, long-lasting infection with certain types of human papillomavirus (HPV) is the main cause of cervical cancer." />
                <SimpleText copy="Cervical cancer is highly preventable through early screening and vaccines. When cervical cancer is found early, it is highly treatable." />
                <SimpleText>
                  <p>
                  This is why acquiring and testing a <strong style={{color: "#9C69AC"}}><em>more complete sample is so important</em></strong>. By aiding in capturing a more complete sample, <strong style={{color: "#9C69AC"}}><em>exCellerator</em></strong> can help ensure that the cells collected and needed to identify HPV infection are more completely transferred.
                  </p>
                </SimpleText>
              </div>
            </div>
          </div>
        </Layout>
      )
    }}
  />
)

export default CerivcalCancerScreening
